import request from "@/utils/request";

/**
 * @return Promise
 */
export function getWebIdCheck(webId, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/web-member/web-id-check",
    isBackground: isBackground,
    method: "get",
    params: {
      webId: webId,
    },
  });
}

/**
 * @return Promise
 */
export function getHpNoCheck(hpNo, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/member_management/web-member/member-hp-check",
    isBackground,
    method: "get",
    params: {
      hpNo: hpNo,
    },
  });
}
