<template>
  <div>
    <ejs-dialog
      ref="webPasswordResetPopupDialog"
      :header="isCreate ? '웹회원 등록' : '웹회원 비밀번호 변경'"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="367"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <div class="window changeWebMemberPassword">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        {{ isCreate ? "웹회원 등록" : "비밀번호 변경" }}
                      </div>
                    </div>
                    <div class="header-right" v-if="!isCreate">
                      <ul class="header-button">
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-custom-shortcut-button="true"
                              shortcut-key="webPasswordResetPopup.shortcuts.delete"
                              :shortcut="{key: 'F4'}"
                              v-on:click.native="onDeleteClicked"
                          >
                            ID 삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">회원명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{ webMemberInfo.memberName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">핸드폰</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    {{
                                      getFormattedPhoneNumber(
                                        webMemberInfo.hpNo
                                      )
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-if="isCreate">
                              <!-- 필수 : required -->
                              <div :class="['title', { required: isCreate }]">
                                웹 ID
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="webId"
                                          :disabled="!isCreate"
                                          v-model="webMemberInfo.webId"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" v-else>
                              <!-- 필수 : required -->
                              <div :class="['title', { required: isCreate }]">
                                웹 ID
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="webId"
                                        :disabled="!isCreate"
                                        v-model="displayWebId"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field password">
                              <!-- 필수 : required -->
                              <div class="title">비밀번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input
                                        id="webPasswordInput"
                                        name="webPasswordInput"
                                        v-on:keydown="preventEnterEvent"
                                        v-model="webMemberInfo.webPassword"
                                        class="e-input"
                                        type="password"
                                        maxlength="50"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <!--                                    <li class="item form-group check">-->
                                  <!--                                      &lt;!&ndash; 포커스 : focus &ndash;&gt;-->
                                  <!--                                      <ul class="check">-->
                                  <!--                                        <li>-->
                                  <!--                                          <label>-->
                                  <!--                                            <input type="checkbox" v-model='webMemberInfo.isSendSMS' />-->
                                  <!--                                            <i></i>-->
                                  <!--                                            <div class="label">-->
                                  <!--                                              SMS 전송-->
                                  <!--                                            </div>-->
                                  <!--                                          </label>-->
                                  <!--                                        </li>-->
                                  <!--                                      </ul>-->
                                  <!--                                    </li>-->
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-caption">
                      ※ 비밀번호 미입력시 임의 비밀번호가 생성됩니다.<br />
                      임시 비밀번호는 회원정보 저장시 SMS로 전송됩니다.
                    </div>
                    <div class="body-box">
                      <div class="title">[SMS 문구 예시]</div>
                      <div>
                        <strong>{{ webMemberInfo.memberName }}</strong> 님 웹
                        ID는 {{ displayWebId }} 비밀번호는 ********
                        입니다.
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                v-on:click.native="onConfirmClicked"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  :is-icon-custom="true"
                  v-on:click.native="onCloseClicked"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getMemberDetail, deleteWebId } from "@/api/member";
import { getFormattedPhoneNumber } from "@/utils/string";
import { validateFormRefs } from "@/utils/formUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  // TODO : dialog 용 mixin 하나 제작해서 공통 로직 거기로 넣기
  name: "webPasswordResetPopup",
  mixins: [commonMixin, confirmDialogMixin],
  props: [],
  components: {
    ErpButton,
    InputText,
  },
  created: function () {
    this.webId = this.$props.popupKey;
  },
  mounted: function () {},
  data: function () {
    return {
      webMemberInfo: {
        memberId: null,
        memberName: null,
        hpNo: null,
        webId: null,
        isSendSMS: true,
      },
      animationSettings: { effect: "None" },
      classCodeFields: { text: "className", value: "classCode" },
      boolParams: { params: { checked: true } },
      isInit: true,
      webId: "",
      isCreate: false,
      validateRefList: {
        webId: { required: true },
      },
    };
  },
  computed: {
    displayWebId() {
      const webId = this.webMemberInfo?.webId;

      if (["KAKAO", "NAVER"].includes(webId?.split(":")?.[0])) {
        return webId?.split(":")?.[0];
      } else {
        return webId;
      }
    }
  },
  methods: {
    validateFormRefs,
    getFormattedPhoneNumber,
    showPopup: function (data) {
      console.log(data);
      this.isCreate = data.isCreate;
      this.webMemberInfo.memberId = data.memberId;
      this.setWebMemberInfo();
      if (this.isCreate) {
        this.webMemberInfo.webId = data.webId;
      }
      this.$refs.webPasswordResetPopupDialog.show();
    },
    onViewButtonClicked() {
      this.setMemberList();
    },
    setWebMemberInfo() {
      getMemberDetail(this.webMemberInfo.memberId).then((response) => {
        console.log(response);
        this.webMemberInfo.memberName = response.value.memberDetail.memberName;
        this.webMemberInfo.hpNo = response.value.memberDetail.hpNo;
        if (!this.isCreate) {
          this.webMemberInfo.webId = response.value.memberDetail.webId;
          this.webMemberInfo.snsEntryFlag = response.value.memberDetail.snsEntryFlag;
        }
      });
    },
    preventEnterEvent: function (event) {
      // TODO : mixin 또는 공통모듈로
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    dialogClose() {
      this.$emit("popupClosed", null);
    },
    async onDeleteClicked() {
      if (await this.confirm("웹 ID를 삭제하시겠습니까?")) {
        deleteWebId(this.webMemberInfo).then(() => {
          this.infoToast(this.$t("main.popupMessage.deleted"));
          this.$emit("popupConfirmed", { isDeleted: true });
        });
      }
    },
    onConfirmClicked() {
      if (!this.validate()) {
        return;
      }

      if (this.isCreate) {
        GolfErpAPI.createMemberWebIdInformation(this.webMemberInfo.memberId, {
          id: this.webMemberInfo.webId,
          password: this.webMemberInfo.webPassword,
        }).then(() => {
          this.infoToast(this.$t("main.popupMessage.saved"));
          this.$emit("popupConfirmed");
        });
      } else {
        if (this.webMemberInfo.snsEntryFlag) {
          this.infoToast("SNS로 가입된 회원입니다.<br>비밀번호를 변경할 수 없습니다.");
          return;
        }
        GolfErpAPI.patchMemberWebIdInformation(this.webMemberInfo.memberId, {
          id: this.webMemberInfo.webId,
          password: this.webMemberInfo.webPassword,
        }).then(() => {
          this.infoToast(this.$t("main.popupMessage.saved"));
          this.$emit("popupConfirmed");
        });
      }
    },
    onCloseClicked() {
      this.$refs.webPasswordResetPopupDialog.hide();
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
